import logo from './logo.svg';
import img1 from './assets/img/img-1.jpg';

import c1 from './assets/img/c1.png';
import c2 from './assets/img/c2.png';
import c3 from './assets/img/c3.png';
import c4 from './assets/img/c4.png';
import c5 from './assets/img/c5.png';
import c6 from './assets/img/c6.png';
import c7 from './assets/img/c7.png';
import c8 from './assets/img/c8.png';
import c9 from './assets/img/c9.png';
import c10 from './assets/img/c10.png';
import c11 from './assets/img/c11.jpg';
import c12 from './assets/img/c12.jpg';
import c13 from './assets/img/c13.jpg';
import c14 from './assets/img/c14.png';
import c15 from './assets/img/c15.png';
import c16 from './assets/img/c16.jpg';
import map from './assets/img/map.png';
import goToDown from './assets/img/go-to-down.gif';
import callImage from './assets/img/call-image.jpg';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import TopBar from './TopBar';
import $ from 'jquery';
import React, { useState } from "react";

function ContactUs() {
	
	return (
    <>
		<div className="ds-contact-us ds-main">
			<TopBar />
			<div className="ds-banner p-3">
				<Header />
				<div class="container wow animated fadeInLeft">
					<div class="mt-3 row">
						<div class="col-lg-12" align="center">
							<div class="p-3">
								<h1 class="display-5 ds-cyan p-2 text-white ds-text-shadow">Contact Us</h1>
								<p class="fw-light text-white badge bg-dark">Home / Contact Us</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<section className="wow animated fadeInUp ds-welcome-text mt-5 pt-3 text-center">
				<div className="container">
					<div className="row m-0">
						<div className="col-lg-6 wow animated fadeInUp" align="center">
							<h5>Send me Message</h5>
							<form id="frmContact" action="https://fabstar.in/send_mail.php" class="p-3">
								<input type="text" placeholder="*Name" class="form-control m-3 p-2 mr-2" name="name" required/>
								<input type="number" placeholder="*Mobile" class="form-control m-3 p-2 mr-2" name="mobile" required/>
								<input type="email" placeholder="*Email" class="form-control m-3 p-2 mr-2" name="email" required/>
								<textarea placeholder="*Message" class="form-control m-3 p-2 mr-2" name="message" required></textarea>
								<button id="ds_btn_submit" type="submit" class="btn ds-btn btn-dark m-3 p-2">
									<span class="fa fa-envelope"></span> Send
								</button>
							</form>
						</div>
						<div className="col-lg-6 wow animated fadeInUp" align="center">
							 <h5>Address</h5>
							  <a target="_blank" className="ds-magenta" href="https://www.google.co.in/maps/@13.1776163,80.2087542,17z/data=!5m1!1e1?entry=ttu">
							  <img src={map} className="img-fluid ds-width-200"/>
							  <p>Plot No 21, Sumangali Nagar, <br/> Vadaperumbakkam, Chennai, 600060</p>
							  </a>
							  
							  <h5>GST No.</h5>
							  <p className="ds-magenta">33DUVPS2489M2ZG</p>
							  
							  <h5>Contact</h5>
							  <p><a className="ds-magenta" href="tel:9677415302">9677415302</a></p>
							  
							  <h5>Email</h5>
							  <p><a className="ds-magenta" href="mailto:fabstarindia@gmail.com">fabstarindia@gmail.com</a></p>
						</div>
					</div>
				</div>
			</section>
			
			<section className="bg-light mt-3">
				<div className="container">
					<div className="row">
							<div className="col-lg-6 wow animated fadeInUp" align="center">
								<div className="mt-4">
									<h2><span className="ds-magenta">For</span>	Enquiry</h2>
									<h4 class="display-4">24x5 <span className="ds-magenta">Mon</span>-Fri</h4>
								</div>
								<div className="mt-4">
									<a className="ds-magenta" href="tel:9677415302">
									<button class="btn btn-dark" type="button">
										<span class="fa fa-phone"></span>&nbsp;
										Call to Enquiry
									</button>
									</a>
								</div>
							</div>
							<div className="col-lg-6 p-5 wow animated fadeInUp" align="center" align="center">
								<img src={callImage} className="ds-img ds-img-hover img-fluid wow animated fadeInUp" align="center"/>
							</div>
					</div>
				</div>
			</section>
			
			<section className="bg-white ds-ng-5">	
			<h2 class="mt-5 text-center"><span className="ds-magenta">O</span>ur Customers</h2>					
			<div className="container ds-our-customers" align="center">
					<div className="row">
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c1} data-lightbox="image-1" data-title="">
								<img src={c1} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c2} data-lightbox="image-1" data-title="">
								<img src={c2} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c3} data-lightbox="image-1" data-title="">
								<img src={c3} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c4} data-lightbox="image-1" data-title="">
								<img src={c4} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c5} data-lightbox="image-1" data-title="">
								<img src={c5} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c6} data-lightbox="image-1" data-title="">
								<img src={c6} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c7} data-lightbox="image-1" data-title="">
								<img src={c7} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c8} data-lightbox="image-1" data-title="">
								<img src={c8} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c9} data-lightbox="image-1" data-title="">
								<img src={c9} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c10} data-lightbox="image-1" data-title="">
								<img src={c10} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c11} data-lightbox="image-1" data-title="">
								<img src={c11} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c12} data-lightbox="image-1" data-title="">
								<img src={c12} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c13} data-lightbox="image-1" data-title="">
								<img src={c13} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c14} data-lightbox="image-1" data-title="">
								<img src={c14} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c15} data-lightbox="image-1" data-title="">
								<img src={c15} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c16} data-lightbox="image-1" data-title="">
								<img src={c16} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
					</div>
			</div>
			</section>	
				
		</div>
		<Footer/>
		
	</>
  );
}

$(document).ready(
function()
{
	$("form").on("submit",
	function(e)
	{
		e.preventDefault();
		
				var form = e.target;
				var data = new FormData(form);
				$("#ds_btn_submit").hide();
				$("#ds_btn_submit").text("Please wait...");
				
				$.ajax({
						type:'POST',
						url: e.target.getAttribute('action'),
						data:data,
						cache:false,
						contentType: false,
						processData: false,
						success:function(data){
							
							  $(".ds-car-loading").addClass("d-none");
							  $("#ds_btn_submit").fadeIn();
							  $("#ds_btn_submit").text("Confirm Booking");
							  
						   try
						   {
							  
							  var arr = JSON.parse(data);
							  if(arr.result == 1)
							  {
								  $("form").html('<h1 class="text-center"><i class="fa fa-info-circle"></i><br/>Your Message is Sent! Thanking you!</h1>');
							  }
							  else
							  {
								  alert("Message is not Send Please Retry");
								  $("#ds_btn_submit").show();
							  }
						   }
						   catch(err)
						   {
							   console.log("Exception : "+err.message);
						   }
						},
						error: function(data){
							console.log("error");
							console.log(data);
							
						}
					});
	});
});


export default ContactUs;
