import logo from './logo.svg';
import img1 from './assets/img/img-1.jpg';

import bannerImage1 from './assets/img/banner-image-1.jpg';
import bannerImage2 from './assets/img/banner-image-2.jpg';
import bannerImage3 from './assets/img/banner-image-3.jpg';
import bannerImage4 from './assets/img/banner-image-4.jpg';
import bannerImage5 from './assets/img/banner-image-5.jpg';

import galleryImage1 from './assets/img/gallery_1.jpg';
import galleryImage2 from './assets/img/gallery_2.jpg';
import galleryImage3 from './assets/img/gallery_3.jpg';
import galleryImage4 from './assets/img/gallery_4.jpg';
import galleryImage5 from './assets/img/gallery_5.jpg';

import c1 from './assets/img/c1.png';
import c2 from './assets/img/c2.png';
import c3 from './assets/img/c3.png';
import c4 from './assets/img/c4.png';
import c5 from './assets/img/c5.png';
import c6 from './assets/img/c6.png';
import c7 from './assets/img/c7.png';
import c8 from './assets/img/c8.png';
import c9 from './assets/img/c9.png';
import c10 from './assets/img/c10.png';
import c11 from './assets/img/c11.jpg';
import c12 from './assets/img/c12.jpg';
import c13 from './assets/img/c13.jpg';
import c14 from './assets/img/c14.png';
import c15 from './assets/img/c15.png';
import c16 from './assets/img/c16.jpg';

import goToDown from './assets/img/go-to-down.gif';
import callImage from './assets/img/call-image.jpg';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import TopBar from './TopBar';
import $ from 'jquery';
import React, { useState } from "react";

function AboutUs() {
	
	return (
    <>
		<div className="ds-about-us ds-main">
			<TopBar />
			<div className="ds-banner p-3">
				<Header />
				<div class="container wow animated fadeInLeft">
					<div class="mt-3 row">
						<div class="col-lg-12" align="center">
							<div class="p-3">
								<h1 class="display-5 ds-cyan p-2 text-white ds-text-shadow">About Us</h1>
								<p class="fw-light text-white badge bg-dark">Home / About Us</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<section className="wow animated fadeInUp ds-welcome-text mt-5 pt-3 text-center">
				<h1 className="display-5"> About <span className="ds-cyan wow animated fadeInLeft">F</span><span className="ds-magenta wow animated fadeInUp">a</span><span className="ds-yellow wow animated fadeInRight">b</span>star </h1>
				<div className="text-center">
					<img src={goToDown} className="ds-width-50"/>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-lg-6 wow animated fadeInUp">
							<img src={img1} className="ds-img img-fluid wow animated fadeInUp"/>
						</div>
						<div className="col-lg-6 wow animated fadeInUp">
							
							<p align="justify" className="fw-light fs-6">We are one of the fastest commercial offset printers in Chennai. We are passionate about the finest, most cost-effective and timely printing services and promotional items available anywhere in the country. Up-to-date on technology, we create beautiful printed projects, with an easy and pain-free workflow system. We pride ourselves on our commitment to great customer service.</p>
							
							<p align="justify" className="fw-light fs-6">Our Offset Printing makes use of advance technology to produce best quality results. In larger volumes offset printing is the most effective and efficient way to produce unrivaled quality and consistency compared with other printing methods. We can produce a large range of work from business cards, envelopes and stationery, to brochures and catalogues, point of sale material and packaging. Our design and execution of all phases, together with our in house capabilities insures that you get the most cost effective and timely execution of all your assignments. We have faith in our ability in advertising by keeping abreast of the latest developments in design trends while innovating many of our own.</p>
						</div>
					</div>
				</div>
			</section>
			
			<section className="bg-white mt-3">
				<div className="container">
					<div className="row">
							<div className="col-lg-6 wow animated fadeInUp">
								<div className="mt-4">
									<h2><span className="ds-magenta">For</span>	Enquiry</h2>
									<h4 class="display-4">24x5 <span className="ds-magenta">Mon</span>-Fri</h4>
								</div>
								<div className="mt-4">
									<a className="text-white" href="tel:9677415302">
									<button class="btn btn-dark" type="button">
										<span class="fa fa-phone"></span>&nbsp;
										Call to Enquiry
									</button>
									</a>
								</div>
							</div>
							<div className="col-lg-6 wow animated fadeInUp" align="center">
								<img src={callImage} className="ds-img img-fluid wow animated fadeInUp"/>
							</div>
					</div>
				</div>
			</section>
			
			<section className="bg-white ds-ng-5">	
			<h2 class="mt-5 text-center"><span className="ds-magenta">O</span>ur Customers</h2>					
			<div className="container ds-our-customers" align="center">
					<div className="row">
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c1} data-lightbox="image-1" data-title="">
								<img src={c1} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c2} data-lightbox="image-1" data-title="">
								<img src={c2} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c3} data-lightbox="image-1" data-title="">
								<img src={c3} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c4} data-lightbox="image-1" data-title="">
								<img src={c4} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c5} data-lightbox="image-1" data-title="">
								<img src={c5} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c6} data-lightbox="image-1" data-title="">
								<img src={c6} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c7} data-lightbox="image-1" data-title="">
								<img src={c7} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c8} data-lightbox="image-1" data-title="">
								<img src={c8} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c9} data-lightbox="image-1" data-title="">
								<img src={c9} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c10} data-lightbox="image-1" data-title="">
								<img src={c10} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c11} data-lightbox="image-1" data-title="">
								<img src={c11} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c12} data-lightbox="image-1" data-title="">
								<img src={c12} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c13} data-lightbox="image-1" data-title="">
								<img src={c13} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c14} data-lightbox="image-1" data-title="">
								<img src={c14} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c15} data-lightbox="image-1" data-title="">
								<img src={c15} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c16} data-lightbox="image-1" data-title="">
								<img src={c16} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
					</div>
			</div>
			</section>	
				
		</div>
		<Footer/>
		
	</>
  );
}

$(document).ready(
function()
{
});


export default AboutUs;
