import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/all-fontawesome.min.css';  
import './assets/css/animate.css';  
import './assets/lightbox2/dist/css/lightbox.css';
import Popper from 'popper.js'; 
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Make sure to import the BrowserRouter
import App from './App';
import AboutUs from './AboutUs';
import Services from './Services';
import Gallery from './Gallery';
import ContactUs from './ContactUs';
import Header from './Header';
import Footer from './Footer';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <Router>
      <Routes>
        <Route path="/" element={<App />} />
		<Route path="/home" element={<App />} />
		<Route path="/about-us" element={<AboutUs />} />
		<Route path="/services" element={<Services />} />
		<Route path="/gallery" element={<Gallery />} />
		<Route path="/contact-us" element={<ContactUs />} />
        {/* Add more routes here */}
      </Routes>
    </Router>
	 
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

$(document).ready(
function()
{
	$(document).on("click",".ds-close-side-bar",
	function()
	{
		$(".ds-side-bar").hide();
	});
	
	$(document).on("click",".ds-open-side-bar",
	function()
	{
		$(".ds-side-bar").show();
	});
});