import logo from './assets/img/brand_logo.png';
import './App.css';

function TopBar() {
  return (	
	<div className="ds-top-bar bg-dark text-white fs-7" align="right">
		<a className="text-white" href="tel:9677415302">9677415302</a> | <a className="text-white" href="mailto:fabstarindia@gmail.com">fabstarindia@gmail.com</a>
	</div>
  );
}

export default TopBar;
