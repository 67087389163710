import logo from './logo.svg';
import img1 from './assets/img/img-1.jpg';

import bannerImage1 from './assets/img/banner-image-1.jpg';
import bannerImage2 from './assets/img/banner-image-2.jpg';
import bannerImage3 from './assets/img/banner-image-3.jpg';
import bannerImage4 from './assets/img/banner-image-4.jpg';
import bannerImage5 from './assets/img/banner-image-5.jpg';

import galleryImage1 from './assets/img/gallery_1.jpg';
import galleryImage2 from './assets/img/gallery_2.jpg';
import galleryImage3 from './assets/img/gallery_3.jpg';
import galleryImage4 from './assets/img/gallery_4.jpg';
import galleryImage5 from './assets/img/gallery_5.jpg';
import { Outlet, Link } from "react-router-dom";
import c1 from './assets/img/c1.png';
import c2 from './assets/img/c2.png';
import c3 from './assets/img/c3.png';
import c4 from './assets/img/c4.png';
import c5 from './assets/img/c5.png';
import c6 from './assets/img/c6.png';
import c7 from './assets/img/c7.png';
import c8 from './assets/img/c8.png';
import c9 from './assets/img/c9.png';
import c10 from './assets/img/c10.png';
import c11 from './assets/img/c11.jpg';
import c12 from './assets/img/c12.jpg';
import c13 from './assets/img/c13.jpg';
import c14 from './assets/img/c14.png';
import c15 from './assets/img/c15.png';
import c16 from './assets/img/c16.jpg';

import goToDown from './assets/img/go-to-down.gif';
import callImage from './assets/img/call-image.jpg';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import TopBar from './TopBar';
import $ from 'jquery';
import React, { useState } from "react";

function App() {
	
	return (
    <>
		<div className="ds-home ds-main">
			<TopBar />
			<div className="ds-banner p-3">
				<Header />
				<div class="ds-banner-text container wow animated fadeInLeft">
					<div class="mt-3 row">
						<div class="col-lg-6" align="center">
							<div class="p-3 ds-banner-text-section">
								<h1 class="ds-cyan p-2">Digital Sunboard Printing</h1>
								<h2 class="ds-magenta" >ECO VINYLE PRINTS WITH LAMINATION SERVICES IN CHENNAI</h2>
								<hr/>
								<h3>Perfect for office branding, product display, large format printing, roll up banner standee, big size poster printing, sandwich panels translite, LED Frames and more.</h3>
							</div>
						</div>
						<div class="col-lg-6" align="center">
							<img src={bannerImage1} className="ds-img img-fluid wow animated fadeInUp"/>
						</div>
					</div>
				</div>
				<div className="row" align="center">
					<div class="col-12">
						<span data-value="1" className="ds-dots active">&nbsp;</span>
						<span data-value="2" className="ds-dots">&nbsp;</span>
						<span data-value="3" className="ds-dots">&nbsp;</span>
						<span data-value="4" className="ds-dots">&nbsp;</span>
						<span data-value="5" className="ds-dots">&nbsp;</span>
					</div>
				</div>
			</div>
			<section className="wow animated fadeInUp ds-welcome-text mt-5 pt-3 text-center">
				<h1 className=" display-5"> Welcome to <span className="ds-cyan wow animated fadeInLeft">F</span><span className="ds-magenta wow animated fadeInUp">a</span><span className="ds-yellow wow animated fadeInRight">b</span>star </h1>
				<div className="text-center">
					<img src={goToDown} className="ds-width-50"/>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-lg-6 wow animated fadeInUp">
							<img src={img1} className="ds-img img-fluid wow animated fadeInUp"/>
						</div>
						<div className="col-lg-6 wow animated fadeInUp">
							<h2 className="display-7"><span className="ds-magenta wow animated fadeInLeft display-4">11</span> YEARS OF EXPERIENCE IN DIGITAL
PRINTERS</h2>
							<p align="justify" className="fw-light fs-6">We are one of the fastest commercial offset printers in <span className="ds-magenta">Chennai</span>. We are passionate about the finest, most cost-effective and timely printing services and promotional items available anywhere in the country. Up-to-date on technology, we create beautiful printed projects, with an easy and pain-free workflow system. We pride ourselves on our commitment to great customer service.</p>
							
							<p align="justify" className="fw-light fs-6">Our Offset Printing makes use of <span className="ds-magenta">advance technology</span> to produce best quality results. In larger volumes offset printing is the most effective and efficient way to produce unrivaled quality and consistency compared with other printing methods. We can produce a large range of work from business cards, envelopes and stationery, to brochures and catalogues, point of sale material and packaging. Our design and execution of all phases, together with our in house capabilities insures that you get the most cost effective and timely execution of all your assignments. We have faith in our ability in advertising by keeping abreast of the latest developments in design trends while innovating many of our own.</p>
						</div>
					</div>
				</div>
			</section>
			
			<section className="bg-white mt-3">
				<div className="container">
					<div className="row">
							<div className="col-lg-6 wow animated fadeInUp">
								<div className="mt-4">
									<h2><span className="ds-magenta">For</span>	Enquiry</h2>
									<h4 class="display-4">24x5 <span className="ds-magenta">Mon</span>-Fri</h4>
								</div>
								<div className="mt-4">
									<a className="text-white" href="tel:9677415302">
									<button class="btn btn-dark" type="button">
										<span class="fa fa-phone"></span>&nbsp;
										Call to Enquiry
									</button>
									</a>
								</div>
							</div>
							<div className="col-lg-6 wow animated fadeInUp" align="center">
								<img src={callImage} className="ds-img img-fluid wow animated fadeInUp"/>
							</div>
					</div>
				</div>
			</section>
				
			<section className="bg-white ds-gallery-section ds-ng-4">
			<h2 class="mt-5 text-center"><span className="ds-magenta">G</span>allery</h2>
			<div className="container">
					<div className="row">
							<div className="ds-img-container col-lg-4 wow animated fadeInUp">
								<a href={galleryImage1} data-lightbox="image-1" data-title=""><img src={galleryImage1} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/></a>
							</div>
							<div className="ds-img-container col-lg-4 wow animated fadeInUp">
								<a href={galleryImage2} data-lightbox="image-1" data-title=""><img src={galleryImage2} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/></a>
							</div>
							<div className="ds-img-container col-lg-4 wow animated fadeInUp">
								<a href={galleryImage3} data-lightbox="image-1" data-title=""><img src={galleryImage3} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/></a>
							</div>
							<div className="ds-img-container col-lg-4 wow animated fadeInUp">
								<a href={galleryImage4} data-lightbox="image-1" data-title=""><img src={galleryImage4} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/></a>
							</div>
							<div className="ds-img-container col-lg-4 wow animated fadeInUp">
								<a href={galleryImage5} data-lightbox="image-1" data-title=""><img src={galleryImage5} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/></a>
							</div>
					</div>
			</div>
			</section>
			
			<section className="bg-white ds-ng-5">	
			<h2 class="mt-5 text-center"><span className="ds-magenta">O</span>ur Customers</h2>					
			<div className="container ds-our-customers" align="center">
					<div className="row">
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c1} data-lightbox="image-1" data-title="">
								<img src={c1} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c2} data-lightbox="image-1" data-title="">
								<img src={c2} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c3} data-lightbox="image-1" data-title="">
								<img src={c3} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c4} data-lightbox="image-1" data-title="">
								<img src={c4} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c5} data-lightbox="image-1" data-title="">
								<img src={c5} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c6} data-lightbox="image-1" data-title="">
								<img src={c6} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c7} data-lightbox="image-1" data-title="">
								<img src={c7} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c8} data-lightbox="image-1" data-title="">
								<img src={c8} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c9} data-lightbox="image-1" data-title="">
								<img src={c9} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c10} data-lightbox="image-1" data-title="">
								<img src={c10} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c11} data-lightbox="image-1" data-title="">
								<img src={c11} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c12} data-lightbox="image-1" data-title="">
								<img src={c12} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c13} data-lightbox="image-1" data-title="">
								<img src={c13} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c14} data-lightbox="image-1" data-title="">
								<img src={c14} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c15} data-lightbox="image-1" data-title="">
								<img src={c15} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp">
								<a href={c16} data-lightbox="image-1" data-title="">
								<img src={c16} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								</a>
							</div>
					</div>
			</div>
			</section>	
			
			<section className="bg-light pt-2 mt-5 pb-2 ds-ng-5">		
			<h2 class="mt-5 text-center"><span className="ds-magenta">T</span>estimonials</h2>
			
				
			<div className="container ds-testimonials">
					<div className="row">
							<div className="col-lg-4 wow animated fadeInUp" align="center">
								<img src={c14} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								<h3>Lassi Shop</h3>
								<p class="ds-cyan">Great Pleace to Signing.</p>
							</div>
							<div className="col-lg-4 wow animated fadeInUp" align="center">
								<img src={c15} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								<h3>Bharath Peroleum</h3>
								<p class="ds-cyan">Best Service and Support</p>
							</div>
							<div className="col-lg-4 wow animated fadeInUp" align="center">
								<img src={c16} className="ds-img img-fluid wow animated fadeInUp ds-img-hover"/>
								<h3>Sweet Dreams</h3>
								<p class="ds-cyan">Elegant Design and long last</p>
							</div>
					</div>
			</div>
			</section>	
				
		</div>
		<Footer/>
		
	</>
  );
}

$(document).ready(
function()
{
	var second = 1;
	
	var timeout = setInterval(function()
	{
		//$(".ds-banner").attr("style","background:none");
		//$(".ds-banner-text").removeClass('fadeInLeft');
		$(".ds-dots").removeClass("active");
		if(second == 5)
		{
			second = 1;
		}
		else
		{
			second = second+1;
			
		}
		
		changeSlide(second);
		
		
	},10000);
	
	$(document).on("click",".ds-dots",
	function()
	{
		$(".ds-dots").removeClass("active");
		second = parseInt($(this).attr("data-value"));
		changeSlide(second);
		clearInterval(timeout);
		$(this).addClass("active");
		
	});
	
	
	
	
	
	
	
});

function changeSlide(second)
{
	if(second == 1)
		{
			$(".ds-banner").attr("style","background:url('bg-2.jpg') no-repeat; background-size:cover; background-position:center center;");
			
			$(".ds-banner-text").html('<div class="mt-3 row"><div class="col-lg-6" align="center"><div class="p-3 ds-banner-text-section"><h1 class="ds-cyan p-2">Digital Sunboard Printing</h1><h2 class="ds-magenta" >ECO VINYLE PRINTS WITH LAMINATION SERVICES IN CHENNAI</h2><hr/><h3>Perfect for office branding, product display, large format printing, roll up banner standee, big size poster printing, sandwich panels translite, LED Frames and more.</h3></div></div><div class="col-lg-6" align="center"><img src="'+bannerImage1+'" className="ds-img img-fluid wow animated fadeInUp"/></div></div>');
			$(".ds-banner-text").addClass('fadeInLeft');
			$(".ds-dots:nth-child("+second+")").addClass("active");
		}
		else if(second == 2)
		{
			$(".ds-banner").attr("style","background:url('bg-3.jpg') no-repeat; background-size:cover; background-position:center center;");
			
			$(".ds-banner-text").html('<div class="mt-3 row"><div class="col-lg-6" align="center"><div class="p-3 ds-banner-text-section"><h1 class="ds-cyan p-2">Flex Backdrop print & Installation</h1><hr/><h3>All Types of - MS iron frame flex - Backdrops, ans vinyle sunboard custom stands, 100% capoable and reliable of handling last minute backdrop printing requirements,  We also do direct vinyle print & pasting on octanorm panel exhibition stalls.</h3></div></div><div class="col-lg-6" align="center"><img src="'+bannerImage2+'" className="ds-img img-fluid wow animated fadeInUp"/></div></div>');
			$(".ds-banner-text").addClass('fadeInLeft');
			$(".ds-dots:nth-child("+second+")").addClass("active");
		}
		else if(second == 3)
		{
			$(".ds-banner").attr("style","background:url('bg-1.jpg') no-repeat; background-size:cover; background-position:center center;");
			
			$(".ds-banner-text").html('<div class="mt-3 row"><div class="col-lg-6" align="center"><div class="p-3 ds-banner-text-section"><h1 class="ds-cyan p-2">Flex Printing - Outdoor Hoardings</h1><h2>Flex Banners, Stage Backdrops, Glow Sign Boards</h2><hr/><h3>For outdoor advertising, directional signage, non-lite flex frames, GSB board, roll-up flex standee, Event backdrops and dropdowns at mall, Universities, and Buildings.</h3></div></div><div class="col-lg-6" align="center"><img src="'+bannerImage4+'" className="ds-img img-fluid wow animated fadeInUp"/></div></div>');
			
			
			$(".ds-banner-text").addClass('fadeInLeft');
			$(".ds-dots:nth-child("+second+")").addClass("active");
		}
		else if(second == 4)
		{
			$(".ds-banner").attr("style","background:url('bg-1.jpg') no-repeat; background-size:cover; background-position:center center;");
			
			$(".ds-banner-text").html('<div class="mt-3 row"><div class="col-lg-6" align="center"><div class="p-3 ds-banner-text-section"><h1 class="ds-cyan p-2">3D Acrylic Signboard</h1><hr/><h3>Get best deals on 3d acrylic letter sign boards, We use original samsung LED modules with double MS iron framing, hight grade aluminium composite panels, waterproof driver power supplies, and branded vinyl, Contact no!.</h3></div></div><div class="col-lg-6" align="center"><img src="'+bannerImage3+'" className="ds-img img-fluid wow animated fadeInUp"/></div></div>');
			$(".ds-banner-text").addClass('fadeInLeft');
			$(".ds-dots:nth-child("+second+")").addClass("active");
		}
		else if(second == 5)
		{
			
			$(".ds-banner").attr("style","background:url('bg-1.jpg') no-repeat; background-size:cover; background-position:center center;");
			
			$(".ds-banner-text").html('<div class="mt-3 row"><div class="col-lg-6" align="center"><div class="p-3 ds-banner-text-section"><h1 class="ds-cyan p-2">Promotional Canopy - Promo tables</h1><h2>Canopies sizes : 4"x4"x7" - 6"x6"x7" - 8"x8"x7" - 10"x10"x7"</h2></<hr/><h3>We are print canopy in varied sizes on high-quality balckout flex media.  Get best rates on bulk canopy orders today!/h3></div></div><div class="col-lg-6" align="center"><img src="'+bannerImage5+'" className="ds-img img-fluid wow animated fadeInUp"/></div></div>');
			$(".ds-banner-text").addClass('fadeInLeft');
			$(".ds-dots:nth-child("+second+")").addClass("active");
		}
}

export default App;
