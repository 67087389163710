import logo from './assets/img/brand_logo.png';
import './App.css';
import $ from 'jquery';
import { Outlet, Link } from "react-router-dom";

function Header() {
  return (
	<>
	<div className="ds-side-bar p-3">
			<div className="p-3" align="right">
				<span className="fs-4">
					<i className="ds-close-side-bar pt-3 text-white fa fa-times"></i>
				</span>
			</div>
			<Link to="/">
				<div className="ds-mobile-menu-item pt-3 text-white">
					<span className="fa fa-home"></span> Home
				</div>
			</Link>
			<Link to="/about-us">
				<div className="ds-mobile-menu-item pt-3 text-white">
					<span className="fa fa-user"></span> About Us
				</div>
			</Link>
			<Link to="/services">
				<div className="ds-mobile-menu-item pt-3 text-white">
					<span className="fa fa-cog"></span> Services
				</div>
			</Link>
			<Link to="/gallery">
				<div className="ds-mobile-menu-item pt-3 text-white">
					<span className="fa fa-image"></span> Gallery
				</div>
			</Link>
			<Link to="/contact-us">
				<div className="ds-mobile-menu-item pt-3 text-white">
					<span className="fa fa-phone"></span> Contact Us
				</div>
			</Link>
		</div>
	<div className="ds-header container">
		<div className="row m-0">
			<div class="col p-0">
				<a href="/">
				<img src={logo} className="ds-logo wow animated fadeInRight"/>
				</a>
			</div>
			<div class="col-7 d-none-md p-0">
				<div class="ds-menu-bar fw-light  ds-cyan">
					<Link to="/"><div class="ds-menu-item ds-link-home"><i class="fa ds-magenta fa-home"></i> Home</div></Link>
					<Link to="/about-us"><div class="ds-menu-item ds-link-about-us"><i class="fa ds-magenta fa-user"></i> About Us</div></Link>
					<Link to="/services"><div class="ds-menu-item ds-link-services"><i class="fa ds-magenta fa-cog"></i> Services</div></Link>
					<Link to="/gallery"><div class="ds-menu-item ds-link-gallery"><i class="fa ds-magenta fa-image"></i> Gallery</div></Link>
					<Link to="/contact-us"><div class="ds-menu-item ds-link-contact-us"><i class="fa ds-magenta fa-phone"></i> Contact Us</div></Link>
				</div>
			</div>
			<div class="col p-0">
				<a className="text-white" href="tel:9677415302">
				<button class="btn btn-dark ds-btn-circle float-end mt-3 m-1 fs-6" type="button">
					<i class="fa fa-phone"></i>
				</button>
				</a>

					<button class="ds-open-side-bar ds-d-none-lg btn btn-dark ds-btn-circle float-end mt-3  m-1 fs-6" type="button">
						<i class="fa fa-bars"></i>
					</button>
				
			</div>
		</div>
	</div>
	</>
  );
}

$(document).ready(
function()
{
	$('body').on("mouseover",".ds-logo",
	function()
	{
		$(this).attr("src","logo2.png");
	});
	
	$('body').on("mouseleave",".ds-logo",
	function()
	{
		$(this).attr("src","brand_logo.png");
	});
	

});

export default Header;
