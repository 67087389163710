import logo from './assets/img/brand_logo.png';
import map from './assets/img/map.png';
import './App.css';
import boat1 from './assets/img/boat.png';
import boat2 from './assets/img/boat-2.png';
import $ from 'jquery'; 
import bg_6 from './assets/img/bg-6.jpg';
function Footer() {
	
	
$(document).ready(
function()
{
	var LAST_PERCENT = 0;
	var BOAT_IMAGE = boat1;
	setInterval(
	function()
	{
		//(parseInt($(document).height() - window.scrollY)+parseInt(window.scrollY));
		//alert($(document).width());
		var document_height = $(document).height();
		var document_width = $(document).width();
		var scroll_y = window.scrollY;
		var scroll_amount = (document_height - scroll_y);
		var scroll_percent = parseInt((scroll_amount/document_height)*100);
		
		if(scroll_percent<=100)
		{
			if(LAST_PERCENT < scroll_percent)
			{
				$("#ds-boat").css("margin-left",(100-scroll_percent)+"%");
				BOAT_IMAGE = boat2;
				
				
			}
			else if(LAST_PERCENT > scroll_percent)
			{
				$("#ds-boat").css("margin-left",(100-scroll_percent)+"%");
				BOAT_IMAGE = boat1;
				
			}
			
			$("#ds-boat").attr("src",BOAT_IMAGE);
			
			LAST_PERCENT = scroll_percent;
		}
		
		
		/*
		
		*/
		
	},1);
});

  return (	
	<>
	<div className="ds-boat-section">
			<div className="row">
				<div className="col-12">
					<img src={boat1} id="ds-boat" className="ds-width-100"/>
				</div>
			</div>
	</div>
	
	<div className="ds-footer row pt-5 pb-5">
    <div className="col-lg-3" align="center">
      <a href="/" className="d-flex align-items-center m-3 link-dark text-decoration-none">
        <img src={logo} className="ds-logo ds-width-200"/>
      </a>
	  <p className="p-2" align="justify">We offers digital printing services for all marketing & promotional items i.e. Flex Printing, Vinyl Printing, Sunpack Printing, One Way Vision, Mobile Van Ad's, Indoor Outdoor Ad’s, Glow Signage, Acp Signage, Led Signage, Metal Letters, Brass Letters for your company.</p>
	  
	  <hr/>
	  <div className="p-1 display-4">
			<a className="m-1 text-white" href="#"><i className="fab fa-facebook"></i></a>
			<a className="m-1 text-white" href="#"><i className="fab fa-youtube"></i></a>
			<a className="m-1 text-white" href="#"><i className="fa fa-envelope"></i></a>
		  </div>
    </div>
	
	<div class="col-lg-3">
		 <h5>Address</h5>
		  <a target="_blank" className="text-white" href="https://www.google.co.in/maps/@13.1776163,80.2087542,17z/data=!5m1!1e1?entry=ttu">
		  <img src={map} className="img-fluid ds-width-200"/>
		  <p>Plot No 21, Sumangali Nagar, <br/> Vadaperumbakkam, Chennai, 600060</p>
		  </a>
		  
		  <h5>GST No.</h5>
		  <p>33DUVPS2489M2ZG</p>
		  
		  <h5>Contact</h5>
		  <p><a className="text-white" href="tel:9677415302">9677415302</a></p>
		  
		  <h5>Email</h5>
		  <p><a className="text-white" href="mailto:fabstarindia@gmail.com">fabstarindia@gmail.com</a></p>
		  
		  
	</div>
	
	
	<div className="col-lg-3">
      <h5>Our Services</h5>
      <ul className="nav flex-column text-warning">
        <li className="nav-item mb-2"><a href="/services/#" className="nav-link p-0 text-muted"><span className="fa fa-cog"></span> Flex Printing</a></li>
        <li className="nav-item mb-2"><a href="/services/#" className="nav-link p-0 text-muted"><span className="fa fa-cog"></span> Vinyl Printing</a></li>
        <li className="nav-item mb-2"><a href="/services/#" className="nav-link p-0 text-muted"><span className="fa fa-cog"></span> Sunpack Printing</a></li>
        <li className="nav-item mb-2"><a href="/services/#" className="nav-link p-0 text-muted"><span className="fa fa-cog"></span> One Way Vision</a></li>
        <li className="nav-item mb-2"><a href="/services/#" className="nav-link p-0 text-muted"><span className="fa fa-cog"></span> Mobile Van Ad's</a></li>
		<li className="nav-item mb-2"><a href="/services/#" className="nav-link p-0 text-muted"><span className="fa fa-cog"></span> Indoor Outdoor Ad’s</a></li>
		<li className="nav-item mb-2"><a href="/services/#" className="nav-link p-0 text-muted"><span className="fa fa-cog"></span> Glow Signage</a></li>
		<li className="nav-item mb-2"><a href="/services/#" className="nav-link p-0 text-muted"><span className="fa fa-cog"></span> Acp Signage</a></li>
      </ul>
    </div>

    <div className="col-lg-3">
      <h5>Quick links</h5>
      <ul className="nav flex-column text-warning">
        <li className="nav-item mb-2"><a href="/home" className="nav-link p-0 text-muted"><span className="fa fa-home"></span> Home</a></li>
        <li className="nav-item mb-2"><a href="/about-us" className="nav-link p-0 text-muted"><span className="fa fa-user"></span> About us</a></li>
        <li className="nav-item mb-2"><a href="/services" className="nav-link p-0 text-muted"><span className="fa fa-cog"></span> Services</a></li>
        <li className="nav-item mb-2"><a href="/gallery" className="nav-link p-0 text-muted"><span className="fa fa-image"></span> Gallery</a></li>
        <li className="nav-item mb-2"><a href="/contact-us" className="nav-link p-0 text-muted"><span className="fa fa-phone"></span> Contact Us</a></li>
      </ul>
    </div>
	
  </div>
  <div className="col-l2 mb-5" align="center">
	<p className="text-muted fs-7 p-2 bg-light">© All Rights Reserved to Fabstar.in 2024</p>
  </div>
  <img src={bg_6} className="img-fluid"/>
  </>
  );
}



export default Footer;
