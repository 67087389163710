import logo from './logo.svg';
import img1 from './assets/img/img-1.jpg';

import flexPrinting from './assets/img/flex-printing.jpg';
import vinylPrinting from './assets/img/vinyle-printing.jpg';
import oneWayVision from './assets/img/one-way-vision.jpg';
import sunPackPrinting from './assets/img/sunpack-printing.jpg';
import mobileVanAd from './assets/img/mobile-van-ad.jpg';
import outdoorAds from './assets/img/outdoor-advertisements.jpg';
import glowSignage from './assets/img/glow-signage.jpg';
import acpSignage from './assets/img/acp-signage.jpg';
import ledSignage from './assets/img/led-signage.jpg';
import neonSignage from './assets/img/neon-signage.jpg';
import metalSignage from './assets/img/metal-signage.jpg';
import brassSignage from './assets/img/brass-signage.jpg';

import c1 from './assets/img/c1.png';
import c2 from './assets/img/c2.png';
import c3 from './assets/img/c3.png';
import c4 from './assets/img/c4.png';
import c5 from './assets/img/c5.png';
import c6 from './assets/img/c6.png';
import c7 from './assets/img/c7.png';
import c8 from './assets/img/c8.png';
import c9 from './assets/img/c9.png';
import c10 from './assets/img/c10.png';
import c11 from './assets/img/c11.jpg';
import c12 from './assets/img/c12.jpg';
import c13 from './assets/img/c13.jpg';
import c14 from './assets/img/c14.png';
import c15 from './assets/img/c15.png';
import c16 from './assets/img/c16.jpg';

import goToDown from './assets/img/go-to-down.gif';
import callImage from './assets/img/call-image.jpg';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import TopBar from './TopBar';
import $ from 'jquery';
import React, { useState } from "react";

function Gallery() {
	
	return (
    <>
		<div className="ds-gallery ds-main">
			<TopBar />
			<div className="ds-banner p-3">
				<Header />
				<div class="container wow animated fadeInLeft">
					<div class="mt-3 row">
						<div class="col-lg-12" align="center">
							<div class="p-3">
								<h1 class="display-5 ds-cyan p-2 text-white ds-text-shadow">Gallery</h1>
								<p class="fw-light text-white badge bg-dark">Home / Gallery</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<section className="wow animated fadeInUp ds-welcome-text mt-5 pt-3 text-center">
				<h1 className="display-5"> Our Gallery </h1>
				<div className="text-center">
					<img src={goToDown} className="ds-width-50"/>
				</div>
				<div className="ds-services-images container">
					<div className="row m-0">
						<div className="col-lg-6 wow animated fadeInUp" align="center">
							<a href={flexPrinting} data-lightbox="image-1" data-title="Flex Printing">
							<img src={flexPrinting} className="ds-img ds-img-hover img-fluid wow animated fadeInUp" align="center"/>
							<h5 class="fs-4 ds-magenta">Flex Printing</h5>
							</a>
						</div>
						<div className="col-lg-6 wow animated fadeInUp" align="center">
							<a href={vinylPrinting} data-lightbox="image-1" data-title="Vinyle Printing">
							<img src={vinylPrinting} className="ds-img ds-img-hover img-fluid wow animated fadeInUp" align="center"/>
							<h5 class="fs-4 ds-magenta">Vinyle Printing</h5>
							</a>
						</div>
						<div className="col-lg-6 wow animated fadeInUp" align="center">
							<a href={sunPackPrinting} data-lightbox="image-1" data-title="Sunpack Printing">
							<img src={sunPackPrinting} className="ds-img ds-img-hover img-fluid wow animated fadeInUp" align="center"/>
							<h5 class="fs-4 ds-magenta">Sunpack Printing</h5>
							</a>
						</div>
						<div className="col-lg-6 wow animated fadeInUp" align="center">
							<a href={oneWayVision} data-lightbox="image-1" data-title="One Way Vision">
							<img src={oneWayVision} className="ds-img ds-img-hover img-fluid wow animated fadeInUp" align="center"/>
							<h5 class="fs-4 ds-magenta">One Way Vision</h5>
							</a>
						</div>
						<div className="col-lg-6 wow animated fadeInUp" align="center">
							<a href={mobileVanAd} data-lightbox="image-1" data-title="Mobile Van Ad">
							<img src={mobileVanAd} className="ds-img ds-img-hover img-fluid wow animated fadeInUp" align="center"/>
							<h5 class="fs-4 ds-magenta">Mobile Van Ad</h5>
							</a>
						</div>
						<div className="col-lg-6 wow animated fadeInUp" align="center">
							<a href={outdoorAds} data-lightbox="image-1" data-title="Outdoor Ads">
							<img src={outdoorAds} className="ds-img ds-img-hover img-fluid wow animated fadeInUp" align="center"/>
							<h5 class="fs-4 ds-magenta">Outdoor Ads</h5>
							</a>
						</div>
						<div className="col-lg-6 wow animated fadeInUp" align="center">
							<a href={glowSignage} data-lightbox="image-1" data-title="Glow Signage">
							<img src={glowSignage} className="ds-img ds-img-hover img-fluid wow animated fadeInUp" align="center"/>
							<h5 class="fs-4 ds-magenta">Glow Signage</h5>
							</a>
						</div>
						<div className="col-lg-6 wow animated fadeInUp" align="center">
							<a href={acpSignage} data-lightbox="image-1" data-title="ACP Signage">
							<img src={acpSignage} className="ds-img ds-img-hover img-fluid wow animated fadeInUp" align="center"/>
							<h5 class="fs-4 ds-magenta">ACP Signage</h5>
							</a>
						</div>
						<div className="col-lg-6 wow animated fadeInUp" align="center">
							<a href={ledSignage} data-lightbox="image-1" data-title="LED Signage">
							<img src={ledSignage} className="ds-img ds-img-hover img-fluid wow animated fadeInUp" align="center"/>
							<h5 class="fs-4 ds-magenta">LED Signage</h5>
							</a>
						</div>
						<div className="col-lg-6 wow animated fadeInUp" align="center">
							<a href={neonSignage} data-lightbox="image-1" data-title="Neon Signage">
							<img src={neonSignage} className="ds-img ds-img-hover img-fluid wow animated fadeInUp" align="center"/>
							<h5 class="fs-4 ds-magenta">Neon Signage</h5>
							</a>
						</div>
						<div className="col-lg-6 wow animated fadeInUp" align="center">
							<a href={metalSignage} data-lightbox="image-1" data-title="Metal Signage">
							<img src={metalSignage} className="ds-img ds-img-hover img-fluid wow animated fadeInUp" align="center"/>
							<h5 class="fs-4 ds-magenta">Metal Signage</h5>
							</a>
						</div>
						<div className="col-lg-6 wow animated fadeInUp" align="center">
							<a href={brassSignage} data-lightbox="image-1" data-title="Brass Signage">
							<img src={brassSignage} className="ds-img ds-img-hover img-fluid wow animated fadeInUp" align="center"/>
							<h5 class="fs-4 ds-magenta">Brass Signage</h5>
							</a>
						</div>
					</div>
				</div>
			</section>
			
			<section className="bg-light mt-3">
				<div className="container">
					<div className="row">
							<div className="col-lg-6 wow animated fadeInUp" align="center">
								<div className="mt-4">
									<h2><span className="ds-magenta">For</span>	Enquiry</h2>
									<h4 class="display-4">24x5 <span className="ds-magenta">Mon</span>-Fri</h4>
								</div>
								<div className="mt-4">
									<a className="text-white" href="tel:9677415302">
									<button class="btn btn-dark" type="button">
										<span class="fa fa-phone"></span>&nbsp;
										Call to Enquiry
									</button>
									</a>
								</div>
							</div>
							<div className="col-lg-6 wow animated fadeInUp" align="center" align="center">
								<img src={callImage} className="ds-img ds-img-hover img-fluid wow animated fadeInUp" align="center"/>
							</div>
					</div>
				</div>
			</section>
			
			<section className="bg-white ds-ng-5">	
			<h2 class="mt-5 text-center"><span className="ds-magenta">O</span>ur Customers</h2>					
			<div className="container ds-our-customers" align="center">
					<div className="row">
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c1} data-lightbox="image-1" data-title="">
								<img src={c1} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c2} data-lightbox="image-1" data-title="">
								<img src={c2} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c3} data-lightbox="image-1" data-title="">
								<img src={c3} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c4} data-lightbox="image-1" data-title="">
								<img src={c4} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c5} data-lightbox="image-1" data-title="">
								<img src={c5} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c6} data-lightbox="image-1" data-title="">
								<img src={c6} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c7} data-lightbox="image-1" data-title="">
								<img src={c7} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c8} data-lightbox="image-1" data-title="">
								<img src={c8} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c9} data-lightbox="image-1" data-title="">
								<img src={c9} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c10} data-lightbox="image-1" data-title="">
								<img src={c10} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c11} data-lightbox="image-1" data-title="">
								<img src={c11} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c12} data-lightbox="image-1" data-title="">
								<img src={c12} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c13} data-lightbox="image-1" data-title="">
								<img src={c13} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c14} data-lightbox="image-1" data-title="">
								<img src={c14} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c15} data-lightbox="image-1" data-title="">
								<img src={c15} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
							<div className="col-lg-2 wow animated fadeInUp" align="center">
								<a href={c16} data-lightbox="image-1" data-title="">
								<img src={c16} className="ds-img ds-img-hover img-fluid wow animated fadeInUp ds-img ds-img-hover-hover"/>
								</a>
							</div>
					</div>
			</div>
			</section>	
				
		</div>
		<Footer/>
		
	</>
  );
}

$(document).ready(
function()
{
});


export default Gallery;
